import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Button } from './button';

type Props = {
  icon: React.ReactNode;
  title: string;
  submitSearch: (searchText: string) => unknown;
  placeholder: string;
};

export function SearchRow({ icon, title, submitSearch, placeholder }: Props) {
  return (
    <div className="my-8 flex items-center justify-between rounded-l-3xl rounded-r-xl bg-[#F9FAFB] py-2">
      <div className="border-r-2 border-[#E5E7EB] px-6">
        <div className="h-8 w-8">{icon}</div>
      </div>
      <div className="pl-2 text-2xl font-bold">{title}</div>
      <div className="flex-1" />
      <SearchBar submitSearch={submitSearch} placeholder={placeholder} />
    </div>
  );
}

// remove type fo
type SearchBarProps = Omit<Props, 'icon' | 'title'>;

function SearchBar({ submitSearch, placeholder }: SearchBarProps) {
  const [searchText, setSearchText] = useState<string>('');

  function onSubmit() {
    submitSearch(searchText);
  }

  return (
    <form id="search-bar" className="flex" onSubmit={e => e.preventDefault()}>
      <div className="relative mr-2 min-w-[500px] max-w-[600px] rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <FontAwesomeIcon icon={faSearch} className="h-4 w-4 text-gray-400" />
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <Button onClick={onSubmit} disabled={false}>
            <div className="flex h-2 items-center">Search</div>
          </Button>
        </div>

        <input
          id="search"
          name="search"
          value={searchText}
          onKeyDown={e => e.key === 'Enter' && onSubmit()}
          onChange={e => setSearchText(e.target.value)}
          type="text"
          placeholder={placeholder}
          className="block w-full rounded-md border-0 py-1 pl-10 pr-32 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
        />
      </div>
    </form>
  );
}
