import { LineItemTableData } from './line-items-table';
import { useStore } from '../../store/store';
import { PacingTypes } from 'shared/src/types';
import { NewValueParams } from '@ag-grid-community/core';
import { PartialLineItem } from 'shared/src/line-item-types';
import { User } from 'shared/src/user-types';
import { isEqual, uniqBy } from 'lodash';
import {
  Channel,
  ChannelSelection,
  Platform,
  rateForTacticUnitPriceType,
  TacticSelection,
  UnitPriceType
} from 'shared/src/line-item-channels';
import { UTCDate } from '@date-fns/utc';

type Update = Omit<PartialLineItem, 'id' | 'strategy_id'>;

export function updateChannel(update: NewValueParams<LineItemTableData, ChannelSelection>) {
  const { oldValue, newValue, data } = update;
  if (newValue !== oldValue)
    updateLineItem(data, {
      channel: newValue || undefined,
      tactic: undefined,
      unit_price_type: undefined,
      unit_price: undefined,
      target_margin: undefined,
      media_platforms: []
    });
}

export function updateTactic(update: NewValueParams<LineItemTableData, TacticSelection>) {
  const { oldValue, newValue, data } = update;
  if (newValue !== oldValue)
    updateLineItem(data, {
      tactic: newValue || undefined,
      unit_price_type: undefined,
      unit_price: undefined,
      target_margin: undefined,
      media_platforms: []
    });
}

export function updateUnitPriceType(
  update: NewValueParams<LineItemTableData, UnitPriceType>,
  channels: Channel[]
) {
  const { oldValue, newValue, data } = update;
  if (newValue === oldValue) return;
  if (newValue == null) {
    updateLineItem(data, {
      unit_price_type: undefined,
      unit_price: undefined,
      target_margin: undefined
    });
  } else {
    const rate = rateForTacticUnitPriceType(channels, data.tactic, newValue);
    updateLineItem(data, {
      unit_price_type: newValue,
      unit_price: rate.unitPrice,
      target_margin: rate.targetMargin
    });
  }
}

export function updateUnitPrice(update: NewValueParams<LineItemTableData, number>) {
  const { oldValue, newValue, data } = update;
  updateLineItem(data, { unit_price: newValue && newValue !== oldValue ? newValue : 0 });
}

export function updateTargetMargin(update: NewValueParams<LineItemTableData, number>) {
  const { oldValue, newValue, data } = update;
  updateLineItem(data, { target_margin: newValue && newValue !== oldValue ? newValue : 0 });
}

export function updatePrice(update: NewValueParams<LineItemTableData, number>) {
  const { oldValue, newValue, data } = update;
  updateLineItem(data, { price: newValue && newValue !== oldValue ? newValue : 0 });
}

export function updateAudience(update: NewValueParams<LineItemTableData, string>) {
  const { oldValue, newValue, data } = update;
  if (newValue !== oldValue) updateLineItem(data, { audience: newValue || undefined });
}

export function updateAdFormats(update: NewValueParams<LineItemTableData, string>) {
  const { oldValue, newValue, data } = update;
  if (newValue !== oldValue) updateLineItem(data, { ad_formats: newValue || undefined });
}

export function updatePlatforms(update: NewValueParams<LineItemTableData, Platform[]>) {
  const { oldValue, newValue, data } = update;
  const uniqueNew = uniqBy(newValue, val => val.id);
  if (
    !isEqual(
      uniqueNew.sort((a, b) => a.id - b.id),
      oldValue?.sort((a, b) => a.id - b.id)
    )
  ) {
    updateLineItem(data, { media_platforms: uniqueNew || [] });
  }
}

export function updateGeo(update: NewValueParams<LineItemTableData, string>) {
  const { oldValue, newValue, data } = update;
  if (newValue !== oldValue) updateLineItem(data, { geo: newValue || undefined });
}

export function updateTargeting(update: NewValueParams<LineItemTableData, string>) {
  const { oldValue, newValue, data } = update;
  if (newValue !== oldValue) updateLineItem(data, { targeting: newValue || undefined });
}

export function updateName(update: NewValueParams<LineItemTableData, string>) {
  const { oldValue, newValue, data } = update;
  if (newValue !== oldValue) updateLineItem(data, { name: newValue || undefined });
}

export function updateStartDate(update: NewValueParams<LineItemTableData, UTCDate>) {
  const { oldValue, newValue, data } = update;
  if (newValue && newValue !== oldValue) updateLineItem(data, { start_date: newValue });
}

export function updateEndDate(update: NewValueParams<LineItemTableData, UTCDate>) {
  const { oldValue, newValue, data } = update;
  if (newValue && newValue !== oldValue) updateLineItem(data, { end_date: newValue });
}

export function updateMediaTraders(update: NewValueParams<LineItemTableData, User[]>) {
  const { oldValue, newValue, data } = update;
  const uniqueNew = uniqBy(newValue, val => val.id);
  if (
    !isEqual(
      uniqueNew.sort((a, b) => a.id.localeCompare(b.id)),
      oldValue?.sort((a, b) => a.id.localeCompare(b.id))
    )
  ) {
    updateLineItem(data, { media_traders: uniqueNew || [] });
  }
}

export function setIsDeleted(data: LineItemTableData) {
  useStore.getState().removeLineItem(data.strategy_id, data.id);
  if (data.state.type !== 'new') {
    updateLineItem(data, { is_deleted: true });
  }
}

export function updatePacingType(
  update: NewValueParams<LineItemTableData, (typeof PacingTypes)[number]>
) {
  const { oldValue, newValue, data } = update;
  if (newValue && newValue !== oldValue)
    useStore.getState().updateLineItem(
      {
        id: data.id,
        strategy_id: data.strategy_id,
        pacing_type: newValue,
        pacing_details: newValue === 'custom' ? data.pacing_details || { blocks: [] } : null
      },
      data.state.type === 'update' ? data.state.original : undefined
    );
}

export function updateLineItem(data: LineItemTableData, update: Update) {
  const { id, strategy_id, state } = data;
  useStore
    .getState()
    .updateLineItem(
      { id, strategy_id, ...update },
      state.type === 'update' ? state.original : undefined
    );
}
