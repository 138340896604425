import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { client } from '../../utils/trpc-client';
import { formatMoney } from 'shared/src/money-utils';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  ModuleRegistry,
  StatusPanelDef
} from '@ag-grid-community/core';
import { AgGridReact, CustomCellRendererProps } from '@ag-grid-community/react';
import { ZohoCampaign, zohoCampaignStatusValues } from 'shared/src/zoho-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AccountManagerFilter, ClientSuccessDirectorFilter } from './account-manager-filter';
import { useNavigate } from 'react-router-dom';
import { CampaignsTableFilter } from './campaign-filter-types';
import { CampaignTotalStatusPanel } from './campaign-totals-status-panel';
import { PageTitle } from '../shared/page-title';
import { showErrorToast } from '../../components/error-toast';
import { AllCampaignsIcon } from '../../components/nav-icons';
import { SearchRow } from '../../components/search-bar';

ModuleRegistry.registerModules([
  InfiniteRowModelModule,
  MenuModule,
  SetFilterModule,
  StatusBarModule
]);

const filterParams = { values: zohoCampaignStatusValues };

export function ZohoCampaigns() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>('');
  const [gridApi, setGridApi] = useState<GridApi<ZohoCampaign> | null>(null);
  const [columnDefs] = useState<ColDef<ZohoCampaign>[]>([
    {
      field: 'id',
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value !== undefined) {
          return props.value;
        } else {
          return <FontAwesomeIcon icon={faSpinner} className="animate-rotate text-blue-500" />;
        }
      },
      suppressHeaderMenuButton: true
    },
    {
      field: 'Deal_Name',
      headerName: 'Name',
      suppressHeaderMenuButton: true
    },
    {
      field: 'Account_Name.name',
      headerName: 'Account',
      suppressHeaderMenuButton: true
    },
    {
      field: 'Budget',
      valueFormatter: ({ value }) => (value ? formatMoney(value) : ''),
      suppressHeaderMenuButton: true,
      filter: 'agNumberColumnFilter',
      filterParams: {
        allowedCharPattern: '\\d\\-\\,',
        filterOptions: ['equals', 'greaterThan', 'lessThan'],
        maxNumConditions: 2
      }
    },
    {
      field: 'Stage',
      filter: 'agSetColumnFilter',
      filterParams,
      comparator: (a, b) =>
        zohoCampaignStatusValues.indexOf(a) - zohoCampaignStatusValues.indexOf(b),
      suppressHeaderMenuButton: true
    },
    {
      field: 'Lead_Account_Owner.name',
      headerName: 'Lead Account Owner',
      suppressHeaderMenuButton: true,
      filter: AccountManagerFilter
    },
    {
      field: 'Sales_Rep.name',
      headerName: 'Client Success Director',
      suppressHeaderMenuButton: true,
      filter: ClientSuccessDirectorFilter
    }
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: false
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('datasource', {
        getRows: async params => {
          const parsed = CampaignsTableFilter.safeParse(params.filterModel);
          if (!parsed.success) {
            console.error('Failed to parse campaigns filter model', parsed.error);
            return;
          }
          const filterModel = parsed.data;
          if (filterModel.Stage && filterModel.Stage.values.length === 0) {
            params.successCallback([], 0);
            return;
          }
          try {
            const { data, total_count } = await client.campaigns.query({
              page: Math.ceil(params.endRow / 50),
              amName: filterModel['Lead_Account_Owner.name']?.value,
              csdName: filterModel['Sales_Rep.name']?.value,
              price: filterModel['Price'],
              ...(filterModel.Stage && { status: params.filterModel.Stage.values }),
              ...(searchText && { search: searchText })
            });
            params.successCallback(data, total_count);
          } catch (error) {
            console.error('Failed to fetch campaigns', error);
            showErrorToast();
            return;
          }
        }
      });
    }
  }, [gridApi, searchText]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
  }, []);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [{ statusPanel: CampaignTotalStatusPanel }]
    };
  }, []);

  return (
    <div className="flex h-full w-full flex-col p-12">
      <PageTitle title="Bravo | Campaigns" />
      <SearchRow
        icon={<AllCampaignsIcon />}
        title="All Campaigns"
        placeholder="Search by Account Name, Campaign Name, Lead AM, CSD"
        submitSearch={setSearchText}
      />
      <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowBuffer={0}
          rowSelection={'multiple'}
          rowModelType={'infinite'}
          cacheBlockSize={50}
          cacheOverflowSize={2}
          isExternalFilterPresent={() => true}
          infiniteInitialRowCount={100}
          maxBlocksInCache={10}
          onGridReady={onGridReady}
          statusBar={statusBar}
          onRowClicked={event =>
            event.data?.id && navigate(`/campaigns/${event.data.id}/strategy/performance`)
          }
        />
      </div>
    </div>
  );
}
