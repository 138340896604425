import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/pro-light-svg-icons';

export function StagingIndicator() {
  return (
    <div className="fixed left-1/2 flex w-[910px] -translate-x-[455px] items-center gap-2 rounded-b bg-red-400 px-1.5 py-1 text-xs">
      <div className="shrink-0 font-semibold uppercase text-white">Bravo Staging Environment</div>
      <div className="flex shrink-0 items-center gap-1 rounded bg-red-200 pl-[6px]">
        <FontAwesomeIcon icon={faWarning} className="text-[#4C0519]" />
        Staging is used by the development team for testing.
        <div className="rounded bg-white px-[6px] py-0.5">
          For BRKTHRU business, use{' '}
          <a
            className="text-blue-600 underline"
            target="_blank"
            href="https://prod.mediatool.brkthru.com"
            rel="noreferrer">
            https://prod.mediatool.brkthru.com
          </a>
        </div>
      </div>
    </div>
  );
}
