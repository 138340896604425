import React from 'react';

export function BravoLogo() {
  return (
    <svg width="73" height="20" viewBox="0 0 73 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.345459V19.0979H3.97313V17.7351C4.20985 17.9846 4.83685 18.5681 5.45106 18.9059C6.21881 19.3282 6.79463 19.4625 7.83109 19.4625C8.86756 19.4625 13.8964 19.0787 13.8964 11.8618C13.8964 4.71484 8.55046 4.72086 7.85031 4.72164C7.84346 4.72165 7.83705 4.72166 7.83109 4.72166C7.21689 4.72166 6.02687 4.95198 5.25912 5.43183C4.64491 5.81571 4.26104 6.21878 4.14587 6.37233V0.345459H0ZM6.92898 7.7159C8.98273 7.7159 9.80806 9.17463 9.80806 12.1497C9.80806 15.1247 8.63724 16.4491 6.92898 16.4491C5.22073 16.4491 4.0691 15.0863 4.0691 12.1497C4.0691 9.21302 5.14395 7.7159 6.92898 7.7159Z"
        fill="black"
      />
      <path
        d="M16.5449 19.098V5.10568H20.6716V6.50683C21.7772 5.40126 23.7042 5.07369 24.5296 5.0481V8.67573C21.9192 8.88687 20.6716 9.44349 20.6716 10.6527V19.098H16.5449Z"
        fill="black"
      />
      <path
        d="M45.1823 19.0979L40.4414 5.12476H44.8752L47.2936 15.0288L49.7696 5.12476H54.1074L49.3474 19.0979H45.1823Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.1843 0C70.3455 0 71.7466 4.79846 71.7466 7.33205C71.7466 9.86564 70.595 14.6833 64.1843 14.6833C57.7735 14.6833 56.833 9.53935 56.833 7.33205C56.833 5.12476 57.8503 0 64.1843 0ZM64.2226 3.14779C66.9866 3.14779 67.62 5.50864 67.62 7.25528C67.62 9.00192 67.0441 11.5547 64.2226 11.5547C61.4011 11.5547 60.9405 9.0595 60.9405 7.25528C60.9405 5.45106 61.6315 3.14779 64.2226 3.14779Z"
        fill="black"
      />
      <path d="M72.1114 15.6814H56.4492V20H72.1114V15.6814Z" fill="#1B9EFE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.526 9.11717H30.4991C30.5311 8.43259 30.8254 7.16524 32.6872 7.23617C34.6145 7.30959 34.5934 8.36596 34.5879 8.6428C34.5876 8.65545 34.5874 8.66648 34.5874 8.67571V9.90411C33.5253 10.0193 30.9444 10.4108 29.1171 11.0557C26.8331 11.8619 25.8926 13.3398 25.8926 15.2976C25.8926 17.2554 27.2361 19.4435 30.4991 19.4435C33.1095 19.4435 34.4786 18.2279 34.8369 17.62C34.8625 18.1127 34.9636 19.098 35.1632 19.098H38.9444C38.8164 18.8804 38.5605 18.1037 38.5605 16.7371V8.67571C38.5349 8.05511 38.2765 6.65268 37.4473 6.00776C36.4108 5.20162 35.4511 4.74097 32.6872 4.74097C29.9233 4.74097 28.9252 5.12484 27.8887 6.00776C26.8523 6.89068 26.526 8.11909 26.526 9.11717ZM33.9605 12.4676C33.3946 12.6353 32.5963 12.8719 31.7851 13.0903C30.288 13.4934 29.8465 14.0692 29.8465 14.9329C29.8465 15.7966 30.5183 16.4684 31.7851 16.4684C33.0519 16.4684 34.5874 15.5855 34.5874 13.6085V12.2842C34.447 12.3234 34.2301 12.3877 33.9605 12.4676Z"
        fill="black"
      />
    </svg>
  );
}
