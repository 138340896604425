import { z } from 'zod';
import { dateToUTC } from 'shared/src/zod-utils';
import { AgDateFilter, AgNumberFilter, AgSetFilter } from './ag-grid-filter-types';

// TODO[mk] - change to draft and finalised

const mediaPlatformEntityBase = z.object({
  id: z.string(),
  media_platform_id: z.number(),
  media_platform: z.object({ name: z.string(), id: z.number() }),
  entity_id: z.string(),
  raw_entity: z.object({}).catchall(z.any()).optional(),
  entity_type: z.string().optional(),
  entity_name: z.string()
});

export type MediaPlatformEntity = z.infer<typeof mediaPlatformEntityBase> & {
  parent: MediaPlatformEntity | null;
};

export const MediaPlatformEntity: z.ZodType<MediaPlatformEntity> = mediaPlatformEntityBase.extend({
  parent: z.lazy(() => MediaPlatformEntity.nullable())
});

export const discoveredMediaPlatformEntityBase = mediaPlatformEntityBase.omit({
  id: true,
  media_platform: true
});

export type DiscoveredMediaPlatformEntity = z.infer<typeof discoveredMediaPlatformEntityBase> & {
  parent?: DiscoveredMediaPlatformEntity | null;
};

export const DiscoveredMediaPlatformEntity: z.ZodType<DiscoveredMediaPlatformEntity> =
  discoveredMediaPlatformEntityBase.extend({
    parent: z.lazy(() => DiscoveredMediaPlatformEntity.optional().nullable())
  });

export const NewMediaPlatformEntity = mediaPlatformEntityBase
  .omit({ media_platform: true })
  .extend({
    parent_id: z.string().nullable().optional()
  });

export type NewMediaPlatformEntity = z.infer<typeof NewMediaPlatformEntity>;

export const UpdateMediaPlatformEntity = mediaPlatformEntityBase.omit({ media_platform: true });

export type UpdateMediaPlatformEntity = z.infer<typeof UpdateMediaPlatformEntity>;

export const MediaBuy = z.object({
  id: z.string(),
  // TODO this is redundant as its in the media_platform_entity
  name: z.string(),
  media_platform_id: z.number(),
  media_platform_entity: MediaPlatformEntity,
  start_date: dateToUTC,
  end_date: dateToUTC,
  budget: z.coerce.number()
});

export type MediaBuy = z.infer<typeof MediaBuy>;

export const SyncedPlatformEntity = z.object({
  media_platform_id: z.number(),
  raw_entity: z.object({}).catchall(z.any()).optional(),
  updated_at: dateToUTC.optional(),
  entity_id: z.string(),
  entity_type: z.string(),
  entity_name: z.string(),
  advertiser_id: z.string().optional()
});

export type SyncedPlatformEntity = z.infer<typeof SyncedPlatformEntity>;

export const SyncedMediaBuy = MediaBuy.omit({
  media_platform_entity: true
}).extend({
  updated_at: dateToUTC.optional(),
  entity_id: z.string(),
  entity_type: z.string(),
  advertiser_id: z.string().nullable(),
  advertiser_type: z.string()
});

export type SyncedMediaBuy = z.infer<typeof SyncedMediaBuy>;

export const DiscoveredMediaBuy = MediaBuy.omit({
  media_platform_entity: true,
  budget: true
}).extend({
  media_platform_entity: DiscoveredMediaPlatformEntity,
  budget: z.coerce.number().nullable().optional()
});

export type DiscoveredMediaBuy = z.infer<typeof DiscoveredMediaBuy>;

export const MediaBuyTableRow = MediaBuy.omit({ media_platform_entity: true });
export type MediaBuyTableRow = z.infer<typeof MediaBuyTableRow>;

export const LineItemMediaBuy = z.object({
  id: z.string(),
  line_item_id: z.string(),
  media_buy_id: z.string()
});
export type LineItemMediaBuy = z.infer<typeof LineItemMediaBuy>;

export const NewMediaBuy = MediaBuy.omit({
  media_platform_entity: true
}).extend({
  line_item_id: z.string()
});
export type NewMediaBuy = z.infer<typeof NewMediaBuy>;

export const MediaBuyUpdate = MediaBuy.partial()
  .omit({ id: true, media_platform_entity: true })
  .extend({ id: z.string() });

export type MediaBuyUpdate = z.infer<typeof MediaBuyUpdate>;

export const MediaBuyChanges = z.union([
  z.object({
    type: z.literal('new'),
    data: NewMediaBuy
  }),
  z.object({
    type: z.literal('update'),
    data: MediaBuyUpdate
  })
]);
export type MediaBuyChanges = z.infer<typeof MediaBuyChanges>;

export const MediaBuySortColumn = z.union([
  z.literal('name'),
  z.literal('media_platform_id'),
  z.literal('start_date'),
  z.literal('end_date'),
  z.literal('budget')
]);
export type MediaBuySortColumn = z.infer<typeof MediaBuySortColumn>;
export const mediaBuySortColumns = MediaBuySortColumn.options.map(option => option.value);

export const SortDirection = z.union([z.literal('asc'), z.literal('desc')]);

export const MediaBuySort = z.object({
  column: MediaBuySortColumn,
  direction: SortDirection
});
export type MediaBuySort = z.infer<typeof MediaBuySort>;

export const MediaBuysFilterTypes = z.object({
  media_platform_id: AgSetFilter.optional(),
  budget: AgNumberFilter.optional(),
  start_date: AgDateFilter.optional(),
  end_date: AgDateFilter.optional(),
  campaign_id: AgSetFilter.optional(),
  advertiser_id: AgSetFilter.optional(),
  advertiser_name: AgSetFilter.optional(),
  campaign_name: AgSetFilter.optional()
});
export type MediaBuysFilterTypes = z.infer<typeof MediaBuysFilterTypes>;

export const MediaBuyListRequest = z.object({
  page: z.number(),
  search: z.string().optional(),
  sort: MediaBuySort.optional(),
  filters: MediaBuysFilterTypes
});

export type MediaBuyListRequest = z.infer<typeof MediaBuyListRequest>;

export const MediaBuyListResult = z.object({
  media_buys: z.array(MediaBuyTableRow),
  total: z.number()
});

export type MediaBuyListResult = z.infer<typeof MediaBuyListResult>;
