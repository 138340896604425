import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning, faXmark } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { IntercomIcon } from './intercom-icon';
import { toast, ToastContentProps } from 'react-toastify';
import { useIntercom } from 'react-use-intercom';

export function showErrorToast() {
  toast(toastProps => <ErrorToast {...toastProps} />);
}

type Props = {} & ToastContentProps;

function ErrorToast({ closeToast }: Props) {
  const { showNewMessage: showNewIntercomMessage } = useIntercom();

  return (
    <div className="flex items-center rounded-lg bg-red-600">
      <FontAwesomeIcon icon={faWarning} className="h-7 w-7 px-2 text-red-300" />
      <div className="flex items-center gap-4 rounded-lg bg-red-200 p-2 pl-4">
        <div className="basis-[363px] text-gray-800">
          <div className="mb-1 font-semibold">There was an error getting data for this page.</div>
          <div className="text-sm">If the issue continues, send a message using Intercom.</div>
        </div>
        <div className="flex flex-col items-end justify-between gap-1">
          <div className="flex cursor-pointer rounded bg-red-300 px-1 py-0.5" onClick={closeToast}>
            <FontAwesomeIcon size="lg" className="text-gray-600" icon={faXmark} />
          </div>
          <div
            className="flex cursor-pointer gap-2 rounded rounded-l-3xl bg-[#0171B2] p-2 pl-3"
            onClick={() => showNewIntercomMessage()}>
            <IntercomIcon height={16} width={14} />
            <FontAwesomeIcon color="white" icon={faArrowRight} mask={['fal', 'arrow-right']} />
          </div>
        </div>
      </div>
    </div>
  );
}
