import React, { useState } from 'react';
import { LineItemTableData } from './line-items-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { clsx } from 'clsx';
import Modal from '../../components/modal';
import { DialogTitle } from '@headlessui/react';
import { Button, SecondaryButton } from '../../components/button';
import { setIsDeleted } from './line-items-update-helper';

export function LineItemActions({ data }: { data: LineItemTableData }) {
  const [hovered, setHovered] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  return (
    <div className="relative">
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={clsx(
          'shadow-l-lg fixed right-0 h-full border-l-[1px] border-gray-200 bg-white px-2 transition-[width]',
          hovered ? 'w-[90px]' : 'w-[36px]'
        )}>
        <div className="flex h-full w-full items-center justify-center">
          <div
            onClick={() => setDeleteModalOpen(true)}
            className="flex cursor-pointer items-center rounded bg-red-300 p-1 text-sm font-light text-white transition-all hover:bg-red-500">
            <FontAwesomeIcon icon={faTrashCan} className={clsx(hovered && 'mr-2')} />
            {hovered && <div>Delete</div>}
          </div>
        </div>
      </div>
      <Modal
        className="pt-5"
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        header={<DeleteHeader lineItem={data} />}
        content={<DeleteBody lineItem={data} />}
        footer={<Footer lineItem={data} onClose={() => setDeleteModalOpen(false)} />}
      />
    </div>
  );
}

function DeleteHeader({ lineItem }: { lineItem: LineItemTableData }) {
  return (
    <div className="flex h-12 justify-between px-4">
      <DialogTitle as="h3" className="text-md font-semibold leading-6 text-gray-900">
        {lineItem.media_buys.length > 0 ? 'Unable to delete Line Item' : 'Confirm Delete'}
      </DialogTitle>
    </div>
  );
}

function DeleteBody({ lineItem }: { lineItem: LineItemTableData }) {
  const numMediaBuys = lineItem.media_buys.length;
  return (
    <div className="w-[450px] p-4 font-light">
      {numMediaBuys > 0
        ? `Cannot delete line items with existing Media Buys. This line item has ${numMediaBuys} existing Media Buy${numMediaBuys > 1 ? 's' : ''}.`
        : 'Are you sure you wish to delete this Line Item?'}
    </div>
  );
}

function Footer({ lineItem, onClose }: { lineItem: LineItemTableData; onClose: () => void }) {
  const numMediaBuys = lineItem.media_buys.length;

  function handleUpdate() {
    setIsDeleted(lineItem);
    onClose();
  }

  return (
    <div className="flex justify-end p-4">
      {numMediaBuys > 0 ? (
        <Button onClick={onClose}>Close</Button>
      ) : (
        <>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <div className="w-2" />
          <Button onClick={handleUpdate}>Confirm Delete</Button>
        </>
      )}
    </div>
  );
}
