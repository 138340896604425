export const BEESWAX_MEDIA_PLATFORM_ID = 1;
export const TTD_MEDIA_PLATFORM_ID = 2;
export const FACEBOOK_MEDIA_PLATFORM_ID = 3;
export const TIKTOK_MEDIA_PLATFORM_ID = 5;
export const VIANT_MEDIA_PLATFORM_ID = 7;
export const YOUTUBE_MEDIA_PLATFORM_ID = 9;
export const VISTAR_MEDIA_PLATFORM_ID = 13;
export const LINKEDIN_MEDIA_PLATFORM_ID = 14;
export const PINTEREST_MEDIA_PLATFORM_ID = 15;
export const BEESWAX_TEST_ADVERTISER_ID = 90;

export function getPlatformName(id: number | undefined) {
  switch (id) {
    case BEESWAX_MEDIA_PLATFORM_ID:
      return 'Beeswax';
    case TTD_MEDIA_PLATFORM_ID:
      return 'Trade Desk';
    case FACEBOOK_MEDIA_PLATFORM_ID:
      return 'Facebook';
    case TIKTOK_MEDIA_PLATFORM_ID:
      return 'TikTok';
    case VIANT_MEDIA_PLATFORM_ID:
      return 'Viant';
    case YOUTUBE_MEDIA_PLATFORM_ID:
      return 'YouTube';
    case VISTAR_MEDIA_PLATFORM_ID:
      return 'Vistar';
    case LINKEDIN_MEDIA_PLATFORM_ID:
      return 'LinkedIn';
    case PINTEREST_MEDIA_PLATFORM_ID:
      return 'Pinterest';
    case BEESWAX_TEST_ADVERTISER_ID:
      return 'Beeswax Test';
    default:
      return 'Unknown';
  }
}
export const MEDIA_PLATFORM_IDS = {
  [BEESWAX_MEDIA_PLATFORM_ID]: 'Beeswax',
  [TTD_MEDIA_PLATFORM_ID]: 'TTD',
  [FACEBOOK_MEDIA_PLATFORM_ID]: 'Facebook',
  [TIKTOK_MEDIA_PLATFORM_ID]: 'Tiktok',
  [VIANT_MEDIA_PLATFORM_ID]: 'Viant',
  [YOUTUBE_MEDIA_PLATFORM_ID]: 'Youtube',
  [VISTAR_MEDIA_PLATFORM_ID]: 'Vistar',
  [LINKEDIN_MEDIA_PLATFORM_ID]: 'LinkedIn',
  [PINTEREST_MEDIA_PLATFORM_ID]: 'Pinterest',
  [BEESWAX_TEST_ADVERTISER_ID]: 'Beeswax Test'
};
