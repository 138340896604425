import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { CombinedMediaBuy } from '../../store/strategy-combiner';
import {
  CellMouseOutEvent,
  CellMouseOverEvent,
  ColDef,
  ColGroupDef,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { defaultColDef, formatCurrency } from '../../components/table-utils';
import { useLocation, useNavigate } from 'react-router-dom';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { MediaBuyUpdate } from 'shared/src/media-buy-types';
import { formatDate } from 'shared/src/date-utils';
import { useStore } from '../../store/store';
import { MetricCellRenderer } from '../line-items/line-item-table-cell-components';
import { findMetrics, hasMetrics } from '../line-items/line-items-table-utils';
import { MediaBuyMetrics } from '../../metrics/metrics-types';
import { MediaBuysStatusPanelLeft, MediaBuysStatusPanelRight } from './media-buys-status-panel';

export type MediaBuysTableData = CombinedMediaBuy & {
  button: '';
  deliveryPacing: number;
  spendPacing: number;
  marginPerformance: number;
  margin: number;
};

type Props = {
  mediaBuys: CombinedMediaBuy[];
  colorMap: Record<string, string>;
  metrics: MediaBuyMetrics[];
};

const autoSizeStrategy:
  | SizeColumnsToFitGridStrategy
  | SizeColumnsToFitProvidedWidthStrategy
  | SizeColumnsToContentStrategy = {
  type: 'fitCellContents'
};

export function MediaBuysTable({ mediaBuys, metrics, colorMap }: Props) {
  const setHighlightedGraph = useStore(state => state.setHighlightedGraph);
  const gridRef = useRef<AgGridReact<MediaBuysTableData>>(null);

  const onCellMouseOut = useCallback(
    (e: CellMouseOutEvent<MediaBuysTableData>) => {
      if (!e.event || !e.data) return;
      if (!e.node.isHovered()) setHighlightedGraph(null);
    },
    [setHighlightedGraph]
  );

  const onCellMouseOver = useCallback(
    (e: CellMouseOverEvent<MediaBuysTableData>) => {
      if (!e.event || !e.data) return;
      if (e.node.isHovered()) {
        setHighlightedGraph(e.data.id);
      }
    },
    [setHighlightedGraph]
  );

  const mediaBuysTableData = useMemo(
    () =>
      mediaBuys.map(mediaBuy => ({
        ...mediaBuy,
        ...findMetrics(mediaBuy.id, metrics)
      })),
    [mediaBuys, metrics]
  );

  useEffect(() => {
    setTimeout(() => gridRef.current?.api?.autoSizeAllColumns(), 250);
  }, [mediaBuysTableData]);

  const onMouseLeave = useCallback(() => {
    setTimeout(() => setHighlightedGraph(null), 500);
  }, [setHighlightedGraph]);

  return (
    <div className="flex h-full w-full flex-col">
      <div
        className="ag-theme-quartz"
        style={{
          height: mediaBuysTableData.length < 4 ? '100%' : 'calc(.33 * (100vh - 150px))', // let the table take up roughly a third of the available height
          width: '100%'
        }}
        onMouseLeave={onMouseLeave}>
        <AgGridReact
          gridOptions={{
            headerHeight: 36,
            rowHeight: 36
          }}
          rowData={mediaBuysTableData}
          ref={gridRef}
          columnDefs={getColumnDefs(colorMap, hasMetrics(metrics))}
          defaultColDef={defaultColDef}
          domLayout={mediaBuysTableData.length < 4 ? 'autoHeight' : 'normal'}
          onCellMouseOver={onCellMouseOver}
          onCellMouseOut={onCellMouseOut}
          autoSizeStrategy={autoSizeStrategy}
          statusBar={{
            statusPanels: [
              { statusPanel: MediaBuysStatusPanelLeft, align: 'left' },
              { statusPanel: MediaBuysStatusPanelRight, align: 'right' }
            ]
          }}
        />
      </div>
    </div>
  );
}

function getColumnDefs(
  colorMap: Record<string, string>,
  showMetrics: boolean
): (ColDef<MediaBuysTableData> | ColGroupDef<CombinedMediaBuy>)[] {
  return [
    {
      field: 'button',
      cellRenderer: ViewButtonComponent,
      cellRendererParams: { colorMap },
      headerName: '',
      pinned: 'left',
      minWidth: 120
    },
    {
      field: 'media_platform_id',
      headerName: 'Platform',
      valueFormatter: params => params.data?.media_platform_entity?.media_platform.name || '',
      cellStyle: ({ data }) => cellStyle(data, 'media_platform_id')
    },
    {
      field: 'name',
      maxWidth: 700,
      initialWidth: 600,
      cellStyle: ({ data }) => cellStyle(data, 'name')
    },
    {
      field: 'deliveryPacing',
      hide: !showMetrics,
      cellRenderer: MetricCellRenderer
    },
    {
      field: 'spendPacing',
      hide: !showMetrics,
      cellRenderer: MetricCellRenderer
    },
    {
      field: 'margin',
      hide: !showMetrics,
      cellRenderer: MetricCellRenderer
    },
    {
      field: 'budget',
      cellDataType: 'number',
      editable: true,
      valueFormatter: params => formatCurrency(params.data?.budget || 0),
      cellStyle: ({ data }) => cellStyle(data, 'budget')
    },
    {
      cellDataType: 'date',
      editable: true,
      field: 'start_date',
      headerName: 'Start date',
      valueFormatter: params => formatDate(params.data?.start_date),
      cellStyle: ({ data }) => cellStyle(data, 'start_date')
    },
    {
      cellDataType: 'date',
      editable: true,
      field: 'end_date',
      headerName: 'End date',
      valueFormatter: params => formatDate(params.data?.end_date),
      cellStyle: ({ data }) => cellStyle(data, 'end_date')
    }
  ];
}

function cellStyle(data: CombinedMediaBuy | undefined, key: keyof MediaBuyUpdate) {
  return data?.state === 'new' || data?.dirty[key]
    ? { fontWeight: 'bold' }
    : { fontWeight: 'normal' };
}

type ViewButtonProps = {
  data: CombinedMediaBuy;
  colorMap: Record<string, string>;
};

function ViewButtonComponent({ data, colorMap }: ViewButtonProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const color = colorMap[data.id];
  return (
    <div className="flex h-full items-center">
      <div className="mr-2 h-[24px] w-[6px] rounded-full" style={{ background: color }} />
      <button
        className="flex h-[28px] items-center justify-center rounded-2xl border-2 border-gray-400 bg-gray-100 px-4 hover:bg-gray-200"
        onClick={() => navigate(`${pathname}/../mediabuys/${data.id}/overview`)}>
        View
      </button>
    </div>
  );
}
