import { clsx } from 'clsx';
import React from 'react';

export type DisplayMode = 'cumulative' | 'discrete';

type Props = {
  displayMode: DisplayMode;
  setDisplayMode: (mode: DisplayMode) => void;
};

export function DisplayModeSelector({ displayMode, setDisplayMode }: Props) {
  return (
    <div className="flex h-[28px]">
      <DisplayModeButton
        onClick={() => setDisplayMode('discrete')}
        label="Daily"
        classes={clsx(
          'rounded-l',
          displayMode === 'discrete' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700'
        )}
      />
      <DisplayModeButton
        onClick={() => setDisplayMode('cumulative')}
        classes={clsx(
          'rounded-r',
          displayMode === 'cumulative' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700'
        )}
        label="Cumulative"
      />
    </div>
  );
}

type DisplayModeButtonProps = {
  onClick: () => void;
  label: string;
  classes: string;
};

function DisplayModeButton({ onClick, label, classes }: DisplayModeButtonProps) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'inline-flex items-center border-b border-r border-t px-4 py-2 text-sm font-medium shadow-sm hover:bg-blue-700 hover:text-white focus:outline-none disabled:bg-blue-500',
        classes
      )}>
      {label}
    </button>
  );
}
