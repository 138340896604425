import { ZohoCampaign } from 'shared/src/zoho-types';
import { PartialLineItem } from 'shared/src/line-item-types';
import { CombinedMediaBuy } from '../../store/strategy-combiner';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { Campaign } from 'shared/src/types';
import { CampaignIcon, LineItemIcon, MediaBuyIcon } from '../../components/nav-icons';

export function CampaignBreadcrumbs({ campaign }: { campaign: Campaign | undefined }) {
  return (
    <div className="mt-6 flex flex-col">
      <div className="flex">
        <AllCampaigns />
      </div>
      <PageName name={campaign?.Deal_Name} icon={<CampaignIcon />} type="Campaign" />
    </div>
  );
}

type LineItemBreadcrumbsProps = {
  campaign: Campaign | undefined;
  name: string | undefined;
};

export function LineItemBreadcrumbs({ campaign, name }: LineItemBreadcrumbsProps) {
  return (
    <div className="mt-6 flex flex-col">
      <div className="flex">
        <AllCampaigns />
        <BreadcrumbLink
          to={`/campaigns/${campaign?.id}/strategy/performance`}
          name={campaign?.Deal_Name}
        />
      </div>
      <PageName name={name} icon={<LineItemIcon />} type="Line Item" />
    </div>
  );
}

type MediaBuyBreadcrumbProps = {
  campaign: ZohoCampaign | undefined;
  lineItem: PartialLineItem;
  mediaBuy: CombinedMediaBuy;
};

export function MediaBuyBreadcrumbs({ campaign, lineItem, mediaBuy }: MediaBuyBreadcrumbProps) {
  return (
    <div className="mt-6 flex flex-col">
      <div className="flex">
        <AllCampaigns />
        <BreadcrumbLink
          to={`/campaigns/${campaign?.id}/strategy/performance`}
          name={campaign?.Deal_Name}
        />
        <div className="mr-1">/</div>
        <BreadcrumbLink
          to={`/campaigns/${campaign?.id}/strategy/lineitems/${lineItem.id}/overview`}
          name={lineItem.name}
        />
      </div>
      <PageName name={mediaBuy.name} icon={<MediaBuyIcon />} type="Media Buy" />
    </div>
  );
}

function AllCampaigns() {
  return (
    <>
      <Link className="mr-1 flex items-center hover:underline" to="/campaigns">
        <FontAwesomeIcon icon={faHouse} className="mr-1" />
        <div>All Campaigns</div>
      </Link>
      <div className="mr-1">/</div>
    </>
  );
}

function BreadcrumbLink({ to, name }: { to: string; name: string | undefined }) {
  return (
    <Link className="mr-1 hover:underline" to={to}>
      {name || '-'}
    </Link>
  );
}

type PageNameProps = {
  name: string | undefined;
  icon: React.ReactNode;
  type: string;
};

function PageName({ name, icon, type }: PageNameProps) {
  return (
    <div className="mt-2 flex items-center">
      <div className="mr-2 flex items-center rounded-l-full rounded-r bg-[#F3F4F6] p-[2px] pr-1 text-sm">
        <div className="h-8 w-8">{icon}</div>
        <div className="ml-2">{type}</div>
      </div>
      <div className="text-2xl font-bold">{name ? name : '-'}</div>
    </div>
  );
}
