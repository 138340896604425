import React from 'react';
import { formatNumber, formatPercentage, formatSpend } from '../../../shared/src/line-item-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { faCaretDown, faCaretUp, faCircleDot } from '@fortawesome/free-solid-svg-icons';

type PrimaryMetric = {
  name: string;
  value: number;
};

type StandardMetric = {
  type: 'number' | 'currency' | 'percentage';
  name: string;
  value: number;
};

type MarginMetric = {
  type: 'margin';
  value: number;
  target: number;
};

type SecondaryMetric = StandardMetric | MarginMetric;

type Props = {
  children: React.ReactNode;
};

export function HeroMetricsContainer({ children }: Props) {
  return <div className="flex justify-between gap-1 rounded-md bg-gray-200 p-1">{children}</div>;
}

export type HeroMetricProps = {
  primary: PrimaryMetric;
  other: SecondaryMetric[];
};

export function HeroMetric({ primary, other }: HeroMetricProps) {
  return (
    <div className="flex-1 justify-center rounded-lg bg-gray-100 px-2 py-1.5 text-center">
      <div className="font-semibold">{primary.name}</div>
      <div className="text-2xl font-semibold">{formatMetricPercentage(primary.value)}</div>
      <div className="mt-1">
        {other.map((otherMetric, idx) =>
          otherMetric.type === 'margin' ? (
            <MarginMetricCmp key={idx} metric={otherMetric} />
          ) : (
            <StandardMetricCmp key={idx} metric={otherMetric} />
          )
        )}
      </div>
    </div>
  );
}

function StandardMetricCmp({ metric }: { metric: StandardMetric }) {
  return (
    <div key={metric.name} className="text-[12px] capitalize text-gray-500">
      {formatMetric(metric)} {metric.name}
    </div>
  );
}

function MarginMetricCmp({ metric }: { metric: MarginMetric }) {
  const { value, target } = metric;
  const diff = marginDiff(value, target);

  return (
    <div
      className={clsx('rounded text-[12px] capitalize', marginColor(diff), marginTextColor(diff))}>
      <FontAwesomeIcon icon={marginIcon(diff)} className={clsx('mr-1', marginIconColor(diff))} />
      {formatMetricPercentage(diff)} {marginPerformanceText(diff)}
    </div>
  );
}

function formatMetric(metric: SecondaryMetric) {
  switch (metric.type) {
    case 'percentage':
    case 'margin':
      return formatMetricPercentage(metric.value);
    case 'number':
      return metric.value ? formatNumber(metric.value).toUpperCase() : '--';
    case 'currency':
      return metric.value ? formatSpend(metric.value).toUpperCase() : '--';
  }
}

export function formatMetricPercentage(value: number) {
  if (!value || value === Infinity) return '--';
  return formatPercentage(value);
}

function marginDiff(actual: number, target: number) {
  if (actual === 0 || target === 0) return 0;
  return (actual - target) / target;
}

function marginIcon(performance: number) {
  return performance > 0 ? faCaretUp : performance < 0 ? faCaretDown : faCircleDot;
}

function marginColor(performance: number) {
  return performance > 0 ? 'bg-[#C2F3D6]' : performance < 0 ? 'bg-[#FEEBEB]' : 'text-gray-500';
}

function marginIconColor(performance: number) {
  return performance > 0 ? 'text-[#2CD673]' : performance < 0 ? 'text-[#F56060]' : 'text-gray-500';
}

function marginTextColor(performance: number) {
  return performance > 0 ? 'text-[#165F34]' : performance < 0 ? 'text-[#AD0B0B]' : 'text-gray-700';
}

function marginPerformanceText(performance: number) {
  return `${performance > 0 ? 'Above' : performance < 0 ? 'Below' : 'On'} target`;
}
