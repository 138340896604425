import React from 'react';
import { Button } from '../../components/button';
import { useStore } from '../../store/store';
import { v4 } from 'uuid';
import { Campaign } from 'shared/src/types';
import { UTCDate } from '@date-fns/utc';
import { addDays, isBefore, isSameDay } from 'date-fns';
import { ZohoCampaign } from 'shared/src/zoho-types';

type Props = {
  strategyId: string;
  campaign: Campaign | undefined;
};

export function NewLineItemButton({ strategyId, campaign }: Props) {
  const addLineItem = useStore(state => state.addLineItem);

  async function createLineItem() {
    const { start_date, end_date } = getDefaultDates(campaign);

    await addLineItem({
      id: v4(),
      description: 'Test',
      strategy_id: strategyId,
      price: campaign?.Budget || 0,
      pacing_type: 'lifetime',
      pacing_details: null,
      audience: '',
      targeting: '',
      ad_formats: '',
      start_date,
      end_date,
      media_traders: [],
      media_platforms: [],
      is_deleted: false
    });
  }

  return (
    <>
      <Button disabled={!campaignHasBudget(campaign)} onClick={createLineItem}>
        <div className="mr-2">New Line Item</div>
      </Button>
    </>
  );
}

function getDefaultDates(campaign: Campaign | undefined) {
  const defaultStartDate = new UTCDate();
  const defaultEndDate = addDays(defaultStartDate, 1);
  const start_date = campaign?.Flight_Date ? new UTCDate(campaign.Flight_Date) : defaultStartDate;
  const end_date = campaign?.End_Date ? new UTCDate(campaign.End_Date) : defaultEndDate;

  // If we get bad data from Zoho (end date earlier than start date), reset to defaults
  return isBefore(end_date, start_date) || isSameDay(end_date, start_date)
    ? { start_date: defaultStartDate, end_date: defaultEndDate }
    : { start_date, end_date };
}

function campaignHasBudget(campaign: ZohoCampaign | undefined): boolean {
  return campaign != null && campaign.Budget != null && campaign.Budget > 0;
}
