import React, { useEffect } from 'react';
import Drawer from '../../components/drawer';
import { changesCount } from './pending-changes-utils';
import { PendingChanges } from './pending-changes';
import { Transition } from '@headlessui/react';
import { useStrategyContext } from '../line-items/strategy-context';

export function PendingChangesButton() {
  const { strategy, refetch } = useStrategyContext();
  const [open, setOpen] = React.useState(false);
  const totalChanged = changesCount(strategy);

  // Close the drawer when there are no pending changes
  useEffect(() => {
    if (totalChanged === 0) {
      setOpen(false);
    }
  }, [totalChanged]);

  if (totalChanged === 0) return null;

  return (
    <>
      <div className="fixed right-8 top-4 z-10">
        <Transition show={true}>
          <div className="pointer-events-auto overflow-hidden rounded-lg bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
            <div className="flex flex-col">
              <button
                disabled={false}
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-8 py-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-blue-300"
                onClick={() => setOpen(true)}>
                <div>
                  {totalChanged} Pending Change{totalChanged > 1 ? 's' : ''}
                </div>
              </button>
            </div>
          </div>
        </Transition>
      </div>

      <Drawer open={open} setOpen={setOpen}>
        <PendingChanges strategy={strategy} refetch={refetch} setOpen={setOpen} />
      </Drawer>
    </>
  );
}
