import React from 'react';

export function AllCampaignsIcon() {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.3506 17.8024C26.9318 16.8631 26.6416 15.6305 25.7023 15.0493C24.763 14.4681 23.5304 14.7583 22.9492 15.6976L26.3506 17.8024ZM20.2465 20.353L20.9675 22.2185H20.9675L20.2465 20.353ZM14.4023 20.7104L13.9172 22.6507H13.9172L14.4023 20.7104ZM9.53251 17.6745L11.0772 16.4041L9.53251 17.6745ZM9.53251 7.32553L7.98781 6.05514L9.53251 7.32553ZM14.4023 4.28963L14.8873 6.22992H14.8873L14.4023 4.28963ZM20.2465 4.64703L20.9675 2.78149L20.9675 2.78149L20.2465 4.64703ZM22.9492 15.6976C22.1859 16.931 20.9904 17.9213 19.5256 18.4874L20.9675 22.2185C23.2083 21.3525 25.1084 19.8099 26.3506 17.8024L22.9492 15.6976ZM19.5256 18.4874C18.0596 19.054 16.4266 19.1549 14.8873 18.7701L13.9172 22.6507C16.2522 23.2344 18.7278 23.0841 20.9675 22.2185L19.5256 18.4874ZM14.8873 18.7701C13.3487 18.3854 12.0147 17.5439 11.0772 16.4041L7.98781 18.9449C9.49208 20.7739 11.5815 22.0667 13.9172 22.6507L14.8873 18.7701ZM11.0772 16.4041C10.142 15.2669 9.6499 13.8942 9.6499 12.5H5.6499C5.6499 14.8481 6.48129 17.1131 7.98781 18.9449L11.0772 16.4041ZM9.6499 12.5C9.6499 11.1058 10.142 9.73306 11.0772 8.59592L7.98781 6.05514C6.48129 7.88695 5.6499 10.1519 5.6499 12.5H9.6499ZM11.0772 8.59592C12.0147 7.45606 13.3487 6.61457 14.8873 6.22992L13.9172 2.34935C11.5815 2.93328 9.49208 4.22605 7.98781 6.05514L11.0772 8.59592ZM14.8873 6.22992C16.4266 5.8451 18.0596 5.94601 19.5256 6.51256L20.9675 2.78149C18.7278 1.91594 16.2522 1.7656 13.9172 2.34935L14.8873 6.22992ZM19.5256 6.51256C20.9904 7.07867 22.1859 8.06898 22.9492 9.30241L26.3506 7.19759C25.1084 5.19011 23.2083 3.64749 20.9675 2.78149L19.5256 6.51256Z"
        fill="#1F2937"
      />
      <path
        d="M6 27H26"
        stroke="#1B9EFE"
        strokeWidth="4"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function AllLineItemsIcon() {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 5C28 3.89543 27.1046 3 26 3C24.8954 3 24 3.89543 24 5H28ZM24 11V13H28V11H24ZM24 5V11H28V5H24Z"
        fill="#1F2937"
      />
      <path
        d="M8 5V3H4V5H8ZM6 21H4C4 22.1046 4.89543 23 6 23V21ZM26 23C27.1046 23 28 22.1046 28 21C28 19.8954 27.1046 19 26 19V23ZM4 5V21H8V5H4ZM6 23H26V19H6V23Z"
        fill="#1F2937"
      />
      <path
        d="M6 27H26"
        stroke="#1B9EFE"
        strokeWidth="4"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function AllMediaBuysIcon() {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 21V23H8V21H4ZM6 5L7.696 3.94C7.22312 3.1834 6.30628 2.83153 5.44863 3.0775C4.59099 3.32347 4 4.10778 4 5H6ZM16 21L14.304 22.06C14.6695 22.6448 15.3104 23 16 23C16.6896 23 17.3305 22.6448 17.696 22.06L16 21ZM26 5H28C28 4.10778 27.409 3.32347 26.5514 3.0775C25.6937 2.83153 24.7769 3.1834 24.304 3.94L26 5ZM24 21C24 22.1046 24.8954 23 26 23C27.1046 23 28 22.1046 28 21H24ZM8 21V5H4V21H8ZM4.304 6.06L14.304 22.06L17.696 19.94L7.696 3.94L4.304 6.06ZM17.696 22.06L27.696 6.06L24.304 3.94L14.304 19.94L17.696 22.06ZM24 5V21H28V5H24Z"
        fill="#1F2937"
      />
      <path
        d="M6 27H26"
        stroke="#1B9EFE"
        strokeWidth="4"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CampaignIcon() {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.7007 22.0524C28.2819 21.1131 27.9917 19.8805 27.0524 19.2993C26.1131 18.7181 24.8805 19.0083 24.2993 19.9476L27.7007 22.0524ZM20.8196 25.2388L21.5405 27.1043L20.8196 25.2388ZM13.944 25.6593L14.429 23.719H14.429L13.944 25.6593ZM8.21483 22.0876L6.67013 23.358H6.67013L8.21483 22.0876ZM8.21483 9.91239L6.67013 8.642L8.21483 9.91239ZM13.944 6.34075L13.4589 4.40046H13.4589L13.944 6.34075ZM20.8196 6.76121L20.0986 8.62674H20.0986L20.8196 6.76121ZM24.2993 19.9476C23.3591 21.467 21.8904 22.6808 20.0986 23.3733L21.5405 27.1043C24.1083 26.112 26.2815 24.3459 27.7007 22.0524L24.2993 19.9476ZM20.0986 23.3733C18.3056 24.0662 16.3102 24.1893 14.429 23.719L13.4589 27.5995C16.1357 28.2688 18.9739 28.0962 21.5405 27.1043L20.0986 23.3733ZM14.429 23.719C12.5486 23.2489 10.9124 22.2191 9.75953 20.8172L6.67013 23.358C8.38985 25.4491 10.7813 26.9302 13.4589 27.5995L14.429 23.719ZM9.75953 20.8172C8.60888 19.4181 8 17.7244 8 16H4C4 18.6783 4.94817 21.2642 6.67013 23.358L9.75953 20.8172ZM8 16C8 14.2756 8.60888 12.5819 9.75953 11.1828L6.67013 8.642C4.94817 10.7358 4 13.3217 4 16H8ZM9.75953 11.1828C10.9124 9.78095 12.5486 8.75114 14.429 8.28103L13.4589 4.40046C10.7813 5.06985 8.38985 6.55095 6.67013 8.642L9.75953 11.1828ZM14.429 8.28103C16.3102 7.81075 18.3056 7.93382 20.0986 8.62674L21.5405 4.89568C18.9739 3.90376 16.1357 3.73125 13.4589 4.40046L14.429 8.28103ZM20.0986 8.62674C21.8904 9.31922 23.3591 10.533 24.2993 12.0524L27.7007 9.94759C26.2815 7.65415 24.1083 5.88803 21.5405 4.89568L20.0986 8.62674Z"
        fill="#1F2937"
      />
    </svg>
  );
}

export function LineItemIcon() {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 6C28 4.89543 27.1046 4 26 4C24.8954 4 24 4.89543 24 6H28ZM24 14V16H28V14H24ZM24 6V14H28V6H24Z"
        fill="#1F2937"
      />
      <path
        d="M8 6V4H4V6H8ZM6 26H4C4 27.1046 4.89543 28 6 28V26ZM26 28C27.1046 28 28 27.1046 28 26C28 24.8954 27.1046 24 26 24V28ZM4 6V26H8V6H4ZM6 28H26V24H6V28Z"
        fill="#1F2937"
      />
    </svg>
  );
}

export function MediaBuyIcon() {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 26V28H8V26H4ZM6 6L7.78885 5.10557C7.37404 4.27594 6.44324 3.84039 5.54049 4.0535C4.63775 4.26661 4 5.07244 4 6H6ZM16 26L14.2111 26.8944C14.5499 27.572 15.2425 28 16 28C16.7575 28 17.4501 27.572 17.7889 26.8944L16 26ZM26 6H28C28 5.07244 27.3622 4.26661 26.4595 4.0535C25.5568 3.84039 24.626 4.27594 24.2111 5.10557L26 6ZM24 26C24 27.1046 24.8954 28 26 28C27.1046 28 28 27.1046 28 26H24ZM8 26V6H4V26H8ZM4.21115 6.89443L14.2111 26.8944L17.7889 25.1056L7.78885 5.10557L4.21115 6.89443ZM17.7889 26.8944L27.7889 6.89443L24.2111 5.10557L14.2111 25.1056L17.7889 26.8944ZM24 6V26H28V6H24Z"
        fill="#1F2937"
      />
    </svg>
  );
}
