import React from 'react';
import Modal from '../../components/modal';
import { AlertButton } from '../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHand } from '@fortawesome/pro-light-svg-icons';

type Props = {
  open: boolean;
  setOpen: (setValue: boolean) => void;
};

export function DisallowZeroPriceModal({ open, setOpen }: Props) {
  return (
    <Modal
      className="max-w-[510px]"
      open={open}
      setOpen={setOpen}
      header={
        <div className="flex flex-col gap-3 rounded-t-2xl bg-red-100 py-6 text-center text-2xl font-semibold text-red-600">
          <FontAwesomeIcon icon={faHand} />
          $0 line items aren’t supported…yet.
        </div>
      }
      content={
        <div className="bg-red-100">
          <div className="flex flex-col gap-4 rounded-t-2xl bg-white px-10 pt-6">
            <div className="font-semibold text-red-600">Don’t use Bravo for this campaign.</div>
            <div>
              <span className="font-semibold">
                <span className="italic">In the future,</span> Bravo will support $0 line items
              </span>{' '}
              for situations like make-goods and client comps.
            </div>
            <div className="font-semibold">
              <span className="italic">For now,</span> move on to another campaign.
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-around px-10 pb-4 pt-9">
          <AlertButton onClick={() => setOpen(false)}>Got it!</AlertButton>
        </div>
      }
    />
  );
}
