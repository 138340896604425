import React from 'react';
import { useStrategyContext } from '../line-items/strategy-context';
import { useParams } from 'react-router-dom';
import { calcMediaBudget, formatPercentageNoDecimal } from '../../../../shared/src/line-item-utils';
import clsx from 'clsx';
import { formatCurrencyNoDecimal } from '../../components/table-utils';

export function MediaBuysStatusPanelLeft() {
  const { strategy } = useStrategyContext();
  const { lineItemId } = useParams();
  const lineItem = strategy.line_items.find(li => li.id === lineItemId);

  if (!lineItem) return null;

  return (
    <div className="flex items-center px-3 py-1">
      <div className="mr-1">Rows:</div>
      <div className="text-xl font-bold">{lineItem.media_buys.length}</div>
    </div>
  );
}

export function MediaBuysStatusPanelRight() {
  const { strategy } = useStrategyContext();
  const { lineItemId } = useParams();
  const lineItem = strategy.line_items.find(li => li.id === lineItemId);

  if (!lineItem) return null;

  const allocatedBudget = lineItem.media_buys.reduce((acc, mb) => acc + mb.budget, 0);
  const budget = calcMediaBudget(lineItem) || 0;
  const percentAllocated = budget ? formatPercentageNoDecimal(allocatedBudget / budget) : 0;
  const remaining = budget ? (budget - allocatedBudget) / budget : 0;
  const alert = remaining < 0;

  return (
    <div className="flex justify-end px-3 py-1">
      <div className="flex items-baseline">
        <div className="mr-1 text-lg font-bold">{percentAllocated}</div> of the{' '}
        <div className="mx-1 text-lg font-bold">{formatCurrencyNoDecimal(budget)}</div> allocated.
      </div>
      <div>
        <div className={clsx('ml-10 flex items-center', alert ? 'border-b-2 border-red-400' : '')}>
          <div className="mr-1 text-sm">Remaining:</div>
          <div className="text-lg font-bold">{formatPercentageNoDecimal(remaining)}</div>
        </div>
      </div>
    </div>
  );
}
