import React from 'react';

type Props = {
  height?: number;
  width?: number;
};
export function IntercomIcon({ height = 25, width = 22 }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1">
        <path
          id="Rectangle 1"
          d="M0 2C0 0.89543 0.895431 0 2 0H20C21.1046 0 22 0.895431 22 2V24.5L16.5 22H2C0.895431 22 0 21.1046 0 20V2Z"
          fill="white"
        />
        <path
          id="Vector 1 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.92633 15.2387C3.78319 15.0052 3.47824 14.9301 3.24283 15.0713C3.00604 15.2134 2.92926 15.5205 3.07133 15.7573C3.08286 15.7759 3.10422 15.8084 3.07133 15.7573L3.07225 15.7589L3.07332 15.7606L3.0759 15.7648L3.08286 15.7759C3.08832 15.7845 3.09541 15.7953 3.10422 15.8084C3.12185 15.8344 3.14638 15.8691 3.1785 15.9111C3.24275 15.9952 3.33734 16.1086 3.46778 16.2413C3.72899 16.5069 4.13236 16.848 4.72076 17.1842C5.90019 17.8582 7.80053 18.5001 10.7501 18.5001C13.6996 18.5001 15.6 17.8582 16.7794 17.1842C17.3678 16.848 17.7712 16.5069 18.0324 16.2413C18.1628 16.1086 18.2574 15.9952 18.3217 15.9111C18.3538 15.8691 18.3783 15.8344 18.3959 15.8084C18.4066 15.7927 18.4169 15.7768 18.4268 15.7606C18.43 15.7554 18.3959 15.8084 18.4288 15.7573C18.5709 15.5205 18.4941 15.2134 18.2573 15.0713C18.0219 14.9301 17.717 15.0052 17.5738 15.2387L17.5677 15.2479C17.5606 15.2585 17.5473 15.2775 17.5271 15.3039C17.4869 15.3565 17.4194 15.4384 17.3193 15.5401C17.1196 15.7432 16.7886 16.0272 16.2833 16.316C15.2752 16.892 13.5505 17.5001 10.7501 17.5001C7.94962 17.5001 6.22496 16.892 5.2169 16.316C4.71154 16.0272 4.38054 15.7432 4.18081 15.5401C4.08078 15.4384 4.01326 15.3565 3.97302 15.3039C3.95289 15.2775 3.93958 15.2585 3.93243 15.2479L3.92633 15.2387Z"
          fill="#0171B2"
        />
      </g>
    </svg>
  );
}
