import React from 'react';

const chartTypes = ['spend', 'delivery'] as const; // TODO[mk] add 'margin' once we have values for it
export type ChartType = (typeof chartTypes)[number];

type ChartTypeSelectorProps = {
  selected: ChartType;
  setSelected: (type: ChartType) => void;
};

export function ChartTypeSelector({ selected, setSelected }: ChartTypeSelectorProps) {
  return (
    <GraphSelector
      label="View"
      options={chartTypes}
      setOption={setSelected}
      selectedOption={selected}
    />
  );
}

export type Granularity = 'day' | 'week' | 'month';
const granularityTypes = ['day', 'week', 'month'] as const;
type Props = {
  granularity: Granularity;
  setGranularity: (granularity: Granularity) => void;
};

export function GranularitySelector({ granularity, setGranularity }: Props) {
  return (
    <GraphSelector
      label="By"
      options={granularityTypes}
      setOption={setGranularity}
      selectedOption={granularity}
    />
  );
}

type GraphSelectorProps<T extends string> = {
  label: string;
  options: ReadonlyArray<T>;
  setOption: (option: T) => void;
  selectedOption: T;
};

function GraphSelector<T extends string>({
  label,
  options,
  setOption,
  selectedOption
}: GraphSelectorProps<T>) {
  return (
    <div className="mr-2 flex h-[28px] items-center rounded border border-gray-200 bg-white">
      <label
        htmlFor="options"
        className="flex h-full items-center border-r border-gray-200 px-2 text-xs text-gray-700">
        {label}
      </label>

      <select
        id="options"
        className="flex h-full items-center rounded border-0 border-gray-300 py-0 text-xs font-bold capitalize"
        onChange={event => setOption(options.find(item => item === event.target.value)!)}
        value={selectedOption}>
        {options.map(item => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
}
